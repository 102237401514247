<template>
<div class="card-box-full">
  <div class="card-box-header">
    Google Cloud Links
  </div>

  <table width="100%">
    <tr>
      <td width="200">
        <table width="200" style="margin-top: 10px; font-size: 13px">
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/home/dashboard?project=nrcc-2017')" color="info" style="width: 100%; margin-top: 5px">
                Dashboard
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/cloud-build/builds?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                Cloud Build
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/storage/browser?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                Cloud Storage
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/gcr/images/nrcc-2017?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                Container Registry
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/run?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                Cloud Run
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/sql/instances?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                Databases
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/errors;time=P1D?project=nrcc-2017&supportedpurview=project')" color="secondary" style="width: 100%; margin-top: 5px">
                Error Reporting
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/compute/healthChecks?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                Health Checks
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/iam-admin/iam?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                IAM & Admin
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/kubernetes/list/overview?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                Kubernetes
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/monitoring?authuser=1&project=nrcc-2017&timeDomain=1h')" color="secondary" style="width: 100%; margin-top: 5px">
                Monitoring
              </CButton>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              <CButton @click="openURL('https://console.cloud.google.com/cloudpubsub/topic/list?project=nrcc-2017')" color="secondary" style="width: 100%; margin-top: 5px">
                Pub/Sub
              </CButton>
            </td>
            <td>
            </td>
          </tr>
        </table>
      </td>
      <td align="center">
        <p>If the links are signing you in under the wrong<br>account, toggle the switch below to try and fix it</p>
        <CButton v-if="gcpAuth && gcpAuth.length > 0" @click="toggleAuthFix(false)" color="success" style="width: 200px;">
          ✓ Fix is turned on
        </CButton>
        <CButton v-else color="secondary" @click="toggleAuthFix(true)" style="width: 200px;">
          Fix is turned off
        </CButton>
      </td>
    </tr>
  </table>

</div>
</template>

<script>
export default {
  name: 'Google-Cloud-Links',
  data() {
    return {
      gcpAuth: ""
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    authUserParameter() {
      if (this.gcpAuth) {
        return this.gcpAuth;
      } else {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    toggleAuthFix(enable) {
      if (enable) {
        this.gcpAuth = "authuser=1"
      } else {
        this.gcpAuth = "";
      }
      StorageManager.shared().setItem("gcp_auth", this.gcpAuth);
    },
    //--------------------------------------------------------------------------
    openURL(url) {
      var auth = this.authUserParameter();
      if (auth && auth.length > 0) {
        if (url.includes("?", 0)) {
          url += "&" + auth;
        } else {
          url += "?" + auth;
        }
      }
      window.open(url, '_blank');
    }
  },
  mounted() {
    this.gcpAuth = StorageManager.shared().getItem("gcp_auth");
  }
}
</script>
